import { TranslateFunction } from '~/Locale';
import { HintCode } from '~/helpers/orchestration/auth';

export const getBankIdStartUrl = (autoStartToken: string) =>
  `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;

export const startBankId = (autoStartToken?: string) => {
  if (!autoStartToken) {
    return;
  }

  const bankIdUrl = getBankIdStartUrl(autoStartToken);
  window.open(bankIdUrl, '_self');
};

export const getBankIdErrorMessage = (
  t: TranslateFunction,
  hintCode: HintCode | undefined
) => {
  const defaultError = t('Something went wrong');

  const translations = {
    started: t(`BANK_ID_SIGNING_started`),
    noClient: t(`BANK_ID_SIGNING_noClient`),
    userSign: t(`BANK_ID_SIGNING_userSign`),
    userCancel: t(`BANK_ID_SIGNING_userCancel`),
    startFailed: t(`BANK_ID_SIGNING_startFailed`),
    maintenance: t(`BANK_ID_SIGNING_maintenance`),
    unauthorized: t(`BANK_ID_SIGNING_unauthorized`),
    internalError: t(`BANK_ID_SIGNING_internalError`),
    undefinedError: t(`BANK_ID_SIGNING_undefinedError`),
    certificateErr: t(`BANK_ID_SIGNING_certificateErr`),
    requestTimeout: t(`BANK_ID_SIGNING_requestTimeout`),
    invalidParameters: t(`BANK_ID_SIGNING_invalidParameters`),
    expiredTransaction: t(`BANK_ID_SIGNING_expiredTransaction`),
    alreadyInProgress: t(`BANK_ID_SIGNING_alreadyInProgress`),
    unsupportedMediaType: t(`BANK_ID_SIGNING_unsupportedMediaType`),
    outstandingTransaction: t(`BANK_ID_SIGNING_outstandingTransaction`)
  };

  if (hintCode) {
    return translations?.[hintCode] ?? defaultError;
  }

  return defaultError;
};
