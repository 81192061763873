import { SubscriptionType } from '~/App/shared/interfaces/EmailSubscription';
import { Session } from '~/App/shared/interfaces/store/Session';
import { orchestration } from '~/config/public';
import { get as getCookie } from '~/helpers/client/cookie';
import axios from '~/helpers/orchestration/axiosWithAuthHandling';
import { getCookieConsent } from '../cookieConsent';

export type Credentials = {
  email: string;
  password: string;
};

export const login = (credentials: Credentials) =>
  axios.post<Session>(`${orchestration.url}/common/auth/login`, {
    auth: {
      ...credentials,
      didomiToken: getCookie('didomi_token'),
      cookieConsent: getCookieConsent()
    },
    withCredentials: true,
    responseType: 'json'
  });

type LoginSocialArgs = {
  state: string;
  nonce: string;
  redirectUri: string;
  idpHint: string;
};

interface LoginSocialResponse {
  uri: string;
}

export const loginSocial = ({
  state,
  nonce,
  redirectUri,
  idpHint
}: LoginSocialArgs) =>
  axios.get<LoginSocialResponse>(
    `${orchestration.url}/common/auth/login/social`,
    {
      params: {
        state: state,
        nonce: nonce,
        redirectUri: redirectUri,
        idpHint: idpHint || 'facebook'
      },
      responseType: 'json'
    }
  );

type LoginSocialCallbackArgs = {
  code: string;
  redirectUri: string;
};

interface LoginSocialCallbackResponse {
  auth: Pick<Session['auth'], 'idToken'>;
}

export const loginSocialCallback = ({
  code,
  redirectUri
}: LoginSocialCallbackArgs) =>
  axios
    .post<LoginSocialCallbackResponse>(
      `${orchestration.url}/common/auth/login/social/callback`,
      {
        code: code,
        redirectUri: redirectUri
      },
      {
        responseType: 'json'
      }
    )
    .then(({ data }) => data);

export const logout = (idToken: string) =>
  axios.post(
    `${orchestration.url}/common/auth/logout`,
    {},
    {
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      withCredentials: true,
      responseType: 'json'
    }
  );

export const isAuthenticated = async (idToken: string) => {
  const { data } = await axios.get<boolean>(
    `${orchestration.url}/common/auth/is-authenticated`,
    {
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      withCredentials: true,
      responseType: 'json'
    }
  );

  return data;
};

export type HintCode =
  | `started`
  | `noClient`
  | `userSign`
  | `userCancel`
  | `startFailed`
  | `maintenance`
  | `unauthorized`
  | `internalError`
  | `undefinedError`
  | `certificateErr`
  | `requestTimeout`
  | `invalidParameters`
  | `expiredTransaction`
  | `alreadyInProgress`
  | `unsupportedMediaType`
  | `outstandingTransaction`;

export type BankIdStatus = 'pending' | 'complete' | 'failed';

export type BankIdStatusResponse = {
  completionData: Record<string, string>;
  hintCode: HintCode;
  orderRef: string;
  qrCode: string;
  status: BankIdStatus;
};

export const getBankIdStatus = async <T = BankIdStatusResponse>(
  orderRef: string,
  overrideEndpoint?: string
) => {
  const endpoint = overrideEndpoint
    ? overrideEndpoint
    : `${orchestration.url}/common/auth/bankid/statuses/${orderRef}`;

  return axios.get<T>(endpoint, {
    responseType: 'json'
  });
};

export interface BankIdAuthStatusResponse extends BankIdStatusResponse {
  auth: Session['auth'];
  member: Session['member'];
}

export interface BankIdAuthResponse {
  orderRef: string;
  qrCode: string;
  autoStartToken: string;
}

export const postBankIdAuthStart = (signal: AbortSignal) => {
  return axios.post<BankIdAuthResponse>(
    `${orchestration.url}/common/auth/bankid/auth`,
    {
      responseType: 'json'
    },
    {
      signal
    }
  );
};

export const postBankIdLogin = (orderRef: string) => {
  return axios.post<Session>(
    `${orchestration.url}/common/auth/bankid/login`,
    { orderRef, cookieConsent: getCookieConsent() },
    {
      responseType: 'json'
    }
  );
};

export const postBankIdCancel = (orderRef: string) => {
  return axios.post(
    `${orchestration.url}/common/auth/bankid/cancel`,
    { orderRef },
    {
      responseType: 'json'
    }
  );
};

export const postBankIdVerify = (orderRef: string, idToken: string) => {
  return axios.post<Session>(
    `${orchestration.url}/common/auth/bankid/verify`,
    { orderRef },
    {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      responseType: 'json'
    }
  );
};

export const postBankIdRegister = (
  orderRef: string,
  email: string,
  emailSubscriptionNames: SubscriptionType[] = []
) => {
  return axios.post<Session>(
    `${orchestration.url}/common/auth/bankid/register`,
    {
      orderRef,
      email,
      emailSubscriptionNames,
      cookieConsent: getCookieConsent()
    },
    {
      responseType: 'json'
    }
  );
};

export const postCheckEmail = (email: string, gRecaptchaResponse: string) => {
  return axios.post<{
    email: boolean;
  }>(
    `${orchestration.url}/common/auth/check-email`,
    { email, gRecaptchaResponse },
    {
      responseType: 'json'
    }
  );
};
