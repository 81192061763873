import { affiliateTrackingEnabled } from '~/config/public/environment';
import { get as getCookie } from '~/helpers/client/cookie';

const onClientSide = typeof window !== 'undefined';

const fromAffiliateClick =
  affiliateTrackingEnabled && onClientSide && !!getCookie('awc');

const affiliateClickId = onClientSide ? getCookie('awc') : null;

export { fromAffiliateClick, affiliateClickId };
