import React, { useEffect } from 'react';
import { useFeatureFlagValue } from '~/App/components/FeatureFlags';
import { cookieConsentManagerKey, isServer } from '~/config/public/environment';

const CookieDeclarationInjection = () => {
  const newCookieConsentManager = useFeatureFlagValue(
    'new-cookie-consent-manager'
  );

  useEffect(() => {
    if (!isServer && newCookieConsentManager && cookieConsentManagerKey) {
      // Dynamically load the Cookiebot cookie declaration script
      const script = document.createElement('script');
      script.id = 'CookieDeclaration';
      script.src = `https://consent.cookiebot.com/${cookieConsentManagerKey}/cd.js`;
      script.type = 'text/javascript';
      script.async = true;
      document.body.appendChild(script);

      // Cleanup to remove the script if the component is unmounted
      return () => {
        if (script) {
          document.body.removeChild(script);
        }
      };
    }
  }, [newCookieConsentManager]);

  if (!newCookieConsentManager || !cookieConsentManagerKey) {
    return null;
  }

  return <div id="CookieDeclaration" />;
};

export default CookieDeclarationInjection;
