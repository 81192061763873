import { useRef } from 'react';
import { AwinConversionRef } from '../../AwinTags/AwinConversionElement';
import { useDynamicScript } from '~/App/shared/hooks/useDynamicScript';
import { googleRecaptchaSource } from '~/config/dynamicScriptsTags';

export const useAwinAffiliateRefs = () => {
  const conversionPixelRef = useRef<AwinConversionRef>(null);
  const conversionTagRef = useRef<AwinConversionRef>(null);

  return {
    conversionPixelRef,
    conversionTagRef
  };
};

export const useRecaptcha = () => useDynamicScript(googleRecaptchaSource);
