import React from 'react';
import Link, { LinkProps } from '~/App/shared/components/Link';
import { BodyLink } from '@kvdbil/components';
import styled from 'styled-components';
import CookiesButtonLink from '~/App/components/Footer/CookiesButtonLink';
import { FooterNavigationData } from '~/App/shared/interfaces/store/CmsData';
import { useFeatureFlagValue } from '../../FeatureFlags';

/**
 * @deprecated TODO: [KVDBIL-4337] remove this
 */
declare global {
  interface Window {
    Didomi?: {
      preferences: {
        show: CallableFunction;
        isVisible: boolean;
        hide: CallableFunction;
      };
    };
  }
}

/**
 * @deprecated TODO: [KVDBIL-4337] remove this
 */
const showDidomiSettings = () => {
  window?.Didomi?.preferences.show();
};

const showCookieConsentSettings = () => {
  window?.Cookiebot?.renew();
};

const SubMenuItem = styled(BodyLink)`
  padding: 0.25rem 0;
  &:first-child {
    padding-top: 0;
  }

  a {
    color: ${({ theme }) => theme.colors.gray.dark3};
  }
`;

export type Item = {
  url?: string;
  text: string;
  relationship?: string[];
};

interface SubMenuProps {
  items: NonNullable<FooterNavigationData['linkGroups']>[0]['links'];
}
const SubMenuItems = ({ items }: SubMenuProps) => {
  const newCookieConsentManager = useFeatureFlagValue(
    'new-cookie-consent-manager'
  );
  return (
    <>
      {items.map((item, key) => {
        return (
          <SubMenuItem key={key} as="li">
            {item?.alternativeActionKey === 'cookie_settings' ? (
              <CookiesButtonLink
                onClick={
                  newCookieConsentManager
                    ? showCookieConsentSettings
                    : showDidomiSettings
                }
              >
                {item.text}
              </CookiesButtonLink>
            ) : (
              <Link
                to={item?.url?.trim() ?? '/'}
                customAttrs={
                  item?.customLinkMeta?.filter(
                    ({ attrName, attrValue }) => attrName && attrValue
                  ) as LinkProps['customAttrs']
                }
              >
                {item.text}
              </Link>
            )}
          </SubMenuItem>
        );
      })}
    </>
  );
};

export default SubMenuItems;
