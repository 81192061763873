export enum AwinConversionType {
  LEAD = 'LEAD',
  REGISTRATION = 'REG',
  MEMBER = 'MEMBER'
}

export enum AwinConversionElementType {
  TAG = 'tag',
  PIXEL = 'pixel'
}
