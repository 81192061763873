import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useMemo,
  ForwardedRef
} from 'react';
import { environment } from '~/config/public/environment';
import {
  AwinConversionElementType,
  AwinConversionType
} from '~/App/shared/types/AwinConversionType';
import { AWIN_CLIENT_ID } from '~/config/constants';
import { fromAffiliateClick } from '~/App/shared/components/AwinTags/helper';

declare global {
  interface Window {
    AWIN?: Record<string, unknown>;
  }
}

export type AwinConversionElementHandle = React.ElementRef<
  typeof AwinConversionElement
>;

export type AwinConversionRef = { appendConversionElement(): void };

export const handleConversion = (
  conversionElementRef: React.RefObject<AwinConversionRef>
) => {
  if (fromAffiliateClick && conversionElementRef.current) {
    conversionElementRef.current.appendConversionElement();
  }
};

interface Props {
  id: string;
  orderRef: string | number;
  commissionGroup: AwinConversionType;
  conversionElement: AwinConversionElementType;
}

type ConversionElementHandle = {
  appendConversionElement: () => void;
};

const AwinConversionElement = forwardRef(
  (
    { id, orderRef, commissionGroup, conversionElement }: Props,
    ref: ForwardedRef<ConversionElementHandle>
  ) => {
    const [tagExecuted, setTagExecuted] = useState(false);
    const [executeTagRequested, setExecuteTagRequested] = useState(false);
    const testMode = Number(environment !== 'production');
    const isMasterTagLoaded = typeof window.AWIN !== 'undefined';
    const onClientSide = typeof window !== 'undefined';

    const conversionHandler = useMemo(() => {
      const appendPixel = () => {
        const fallBackTag = document.createElement('img');
        fallBackTag.setAttribute('id', id);
        fallBackTag.setAttribute('data-testid', id);
        fallBackTag.setAttribute('border', '0');
        fallBackTag.setAttribute('width', '0');
        fallBackTag.setAttribute('height', '0');
        fallBackTag.src =
          `https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=${AWIN_CLIENT_ID}&amount=1.00&cr=SEK&ref=${orderRef}&` +
          `parts=${commissionGroup}:1.00&vc=&ch=aw&testmode=${testMode}
        `;
        document.body.appendChild(fallBackTag);
      };
      const appendTag = () => {
        const conversionTag = document.createElement('script');
        conversionTag.setAttribute('id', id);
        conversionTag.setAttribute('data-testid', id);
        conversionTag.innerHTML = `
          if (typeof AWIN !== "undefined" && typeof AWIN.Tracking !== "undefined") {
            AWIN.Tracking.Sale = {};
            AWIN.Tracking.Sale.amount = "1.00";
            AWIN.Tracking.Sale.orderRef = "${orderRef}";
            AWIN.Tracking.Sale.parts = "${commissionGroup}:1.00";
            AWIN.Tracking.Sale.voucher = "";
            AWIN.Tracking.Sale.currency = "SEK";
            AWIN.Tracking.Sale.test = "${testMode}";
            AWIN.Tracking.Sale.channel = "aw";
            AWIN.Tracking.run();
          }
      `;
        document.body.appendChild(conversionTag);
      };
      return {
        tag: appendTag,
        pixel: appendPixel
      };
    }, [commissionGroup, id, orderRef, testMode]);

    useImperativeHandle(ref, () => ({
      appendConversionElement() {
        if (onClientSide && !tagExecuted) {
          if (!isMasterTagLoaded) {
            setExecuteTagRequested(true);
            return;
          }

          conversionHandler[conversionElement]();
          setTagExecuted(true);
        }
      }
    }));

    useEffect(() => {
      if (isMasterTagLoaded && executeTagRequested) {
        conversionHandler[conversionElement]();
        setTagExecuted(true);
        setExecuteTagRequested(false);
      }
    }, [
      isMasterTagLoaded,
      conversionElement,
      conversionHandler,
      setTagExecuted,
      setExecuteTagRequested,
      executeTagRequested
    ]);

    useEffect(() => {
      return () => {
        if (onClientSide) {
          const existingConversionElement: HTMLElement | null =
            document.getElementById(id);
          if (existingConversionElement) {
            existingConversionElement?.remove();
          }
        }
      };
    }, [id, onClientSide]);

    return null;
  }
);

export default AwinConversionElement;
