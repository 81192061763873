import {
  SellFlow1,
  SellFlow,
  LeadObjectInformation,
  BusinessProposal,
  Interval,
  SelectedSpecItem
} from '../interfaces/store/SellFlow';
import { ReduxStore } from '../interfaces/store';
import { createSelector } from 'reselect';
import {
  compose,
  prop,
  not,
  allPass,
  pathOr,
  assoc,
  __,
  props,
  reduce,
  or,
  toUpper,
  unless,
  equals,
  propOr,
  mergeAll,
  pipe,
  unapply,
  converge,
  pickAll,
  join,
  map,
  values,
  anyPass,
  defaultTo,
  append,
  ifElse,
  sortBy,
  propEq,
  indexBy,
  mergeWith,
  isNil,
  concat,
  filter,
  when,
  always,
  mergeRight
} from 'ramda';
import { SELL_FLOWS } from '~/config/constants';
import { VehicleInfoSummaryProps } from '../components/VehicleInfo/VehicleInfo';
import { isEmpty } from '../utils';
import { getPrivateOrCompanySalesFlow } from '~/App/views/SellCar/views/ReservationPrice/helpers';

type SpreadArrayType<T, R> = (...a: readonly T[]) => R;

// this is the only selector in the other reducer called `SellFlow`
// everything else is located in the `SellFlow1` reducer
// `SellFlow1` is the one used for the main flow
// TODO: merge those and have a single `SellFlow` reducer
export const sellflowRoot = createSelector<ReduxStore, SellFlow, SellFlow>(
  root => root?.sellFlow,
  root => root
);
export const clientMemberEmail = createSelector(
  sellflowRoot,
  root => root?.clientMemberEmail
);
export const sellFlowVehicleObject = createSelector(
  sellflowRoot,
  root => root?.vehicleObject
);

export const sellflowRoot1 = createSelector<ReduxStore, SellFlow1, SellFlow1>(
  root => root?.sellFlow1,
  root => root
);

export const isSalesAgreementLoading = createSelector(
  sellflowRoot1,
  root => root?.salesAgreementLoading || false
);

export const isSelectReservationPriceLoading = createSelector(
  sellflowRoot1,
  root => root?.selectReservationPriceLoading || false
);

export const hasSalesAgreement = createSelector(
  sellflowRoot1,
  root => !isEmpty(root?.salesAgreement)
);

export const salesAgreementSelector = createSelector(
  sellflowRoot1,
  root => root?.salesAgreement
);

export const leadObjectInformation = createSelector(
  sellflowRoot1,
  root => root?.leadObjectInformation
);

export const sellFlowInitialTrafficDate = createSelector(
  leadObjectInformation,
  lead => lead?.initialTrafficDate || null
);

export const sellFlowIsTradeInAvailable = createSelector(
  leadObjectInformation,
  lead => lead?.isTradeInAvailable || false
);

export const sellFlowRequiresManualValuation = createSelector(
  leadObjectInformation,
  lead => lead?.requiresManualValuation || false
);

export const expressPrice = createSelector(
  leadObjectInformation,
  lead => lead?.expressPrice || null
);

export const hasExpressPrice = createSelector(leadObjectInformation, lead =>
  Boolean(lead?.expressPrice)
);

export const salesFlow = createSelector(
  leadObjectInformation,
  lead => lead?.salesFlow
);

export const ticketRole = createSelector(
  leadObjectInformation,
  lead => lead?.ticketRole || ''
);

export const businessProposal = createSelector(
  sellflowRoot1,
  root => root?.businessProposal
);

export const defaultContactDetails = createSelector(
  sellflowRoot1,
  root => root?.defaultContactDetails
);

export const contactDetails = createSelector(
  sellflowRoot1,
  root => root?.contactDetails
);

export const contactDetailsPhone = createSelector(
  contactDetails,
  contactDetails => contactDetails?.phone || ''
);

export const contactDetailsEmail = createSelector(
  contactDetails,
  contactDetails => contactDetails?.email || ''
);

export const isNotSureWhenToSellSelector = createSelector(
  contactDetails,
  contactDetails => contactDetails?.whenToSell === 'NOT_SURE'
);

export const token = createSelector(sellflowRoot1, root => root?.token ?? '');

export const baseObjectId = createSelector(
  businessProposal,
  businessProposal => businessProposal?.baseObjectId || ''
);

export const valuationMin = createSelector(
  businessProposal,
  businessProposal => businessProposal?.reservationPriceInterval?.min ?? 0
);

export const valuationMax = createSelector(
  businessProposal,
  businessProposal => businessProposal?.reservationPriceInterval?.max ?? 0
);

export const valuationValue = createSelector(
  businessProposal,
  businessProposal =>
    businessProposal?.reservationPriceInterval?.recommendation ?? 0
);

export const salesFlowBusinessProposal = createSelector(
  businessProposal,
  businessProposal => businessProposal?.salesFlow || ''
);

export const requiresManualValuation = createSelector(
  sellflowRoot1,
  ({ businessProposal = {}, leadObjectInformation = {} }) =>
    Boolean(
      businessProposal?.requiresManualValuation ||
        leadObjectInformation?.requiresManualValuation
    )
);

export const technicalSpecification = createSelector(
  leadObjectInformation,
  lead => lead?.technicalSpecification
);

export const isTradeIn = createSelector(
  sellflowRoot1,
  ({ businessProposal = {}, leadObjectInformation = {} }) =>
    Boolean(businessProposal?.isTradeIn || leadObjectInformation?.isTradeIn)
);

export const vehicleBrand = createSelector(
  leadObjectInformation,
  lead => lead?.technicalSpecification?.vehicle?.brand ?? ''
);
export const vehicleFamilyName = createSelector(
  leadObjectInformation,
  lead => lead?.technicalSpecification?.vehicle?.familyName ?? ''
);

export const businessProposalBaseObject = createSelector(
  businessProposal,
  businessProposal => businessProposal?.baseObject
);

export const baseObjectState = createSelector(
  businessProposalBaseObject,
  businessProposalBaseObject => businessProposalBaseObject?.state
);

export const isWaitingForSignature = createSelector(
  baseObjectState,
  state => state === 'WAITING_FOR_OWNER_SIGNATURE'
);

export const businessProposalTechnicalSpecification = createSelector(
  businessProposalBaseObject,
  businessProposalBaseObject =>
    businessProposalBaseObject?.technicalSpecification
);

export const businessProposalVehicleBrand = createSelector(
  businessProposalTechnicalSpecification,
  spec => spec?.vehicle?.brand ?? ''
);

export const businessProposalVehicleFamilyName = createSelector(
  businessProposalTechnicalSpecification,
  spec => spec?.vehicle?.familyName ?? ''
);

export const vehicleAddons = createSelector(
  leadObjectInformation,
  lead => lead?.technicalSpecification?.addons ?? []
);

export const addonsSelected = createSelector(
  sellflowRoot1,
  root => root?.addonsSelected || []
);

export const vehicleAddonsWithSelected = createSelector(
  vehicleAddons,
  addonsSelected,
  (add, sel): SelectedSpecItem[] =>
    pipe(
      map(indexBy(prop('key') as SpreadArrayType<unknown, string>)),
      reduce(mergeWith(mergeRight), {}),
      values
    )([add, sel])
);

export const vehicleValueAddons = createSelector(
  leadObjectInformation,
  lead => lead?.technicalSpecification?.valuationStandardAddons ?? []
);

export const valuationStandardAddonsSelected = createSelector(
  sellflowRoot1,
  ({ valuationStandardAddonsSelected }) => valuationStandardAddonsSelected || []
);

export const vehicleValueAddonsWithSelected = createSelector(
  vehicleValueAddons,
  valuationStandardAddonsSelected,
  (val, sel): SelectedSpecItem[] =>
    pipe(
      map(indexBy(prop('key') as SpreadArrayType<unknown, string>)),
      reduce(mergeWith(mergeRight), {}),
      values
    )([val, sel])
);

export const packageSelected = createSelector(
  sellflowRoot1,
  ({ packageSelected }) => packageSelected || ''
);

export const businessProposalSelectedPackage = createSelector(
  businessProposal,
  businessProposal =>
    businessProposal?.baseObject?.includedAddons?.package ?? {}
);

export const businessProposalSelectedAddons = createSelector(
  businessProposal,
  businessProposal => {
    const includedAddOns =
      businessProposal?.baseObject?.includedAddons?.addons ?? [];
    const valuationStandardAddons =
      businessProposal?.baseObject?.includedAddons?.valuationStandardAddons ??
      [];

    return [...includedAddOns, ...valuationStandardAddons].filter(
      ({ value }) => value === true
    );
  }
);

export const vehiclePackages = createSelector(
  technicalSpecification,
  packageSelected,
  // The selected package is stored as a string due to API consistancy
  (techSpec, sel): SelectedSpecItem[] => {
    return pipe(
      propOr([], 'packages'),
      map(
        ifElse(propEq('key', sel), assoc('value', true), assoc('value', false))
      ),
      sortBy(prop('name'))
    )(techSpec);
  }
);

export const registrationPlate = createSelector(
  sellflowRoot1,
  pipe(
    props(['registrationPlate', 'prefilledRegistrationPlate']),
    reduce(or as SpreadArrayType<unknown, string>, ''),
    unless(isNil, toUpper),
    defaultTo('')
  )
);

export const isVehicleInfoPlaceholder = createSelector(
  sellflowRoot1,
  allPass([
    compose(not, isEmpty, prop('prefilledRegistrationPlate')),
    compose(isEmpty, prop('leadObjectInformation')),
    compose(isEmpty, prop('leadObjectInformation'))
  ])
);

export const isVehicleInfoEditable = createSelector(
  sellflowRoot1,
  compose(equals(false), prop('vehicleInfoLocked'))
);

export const isVehicleInfoSummary = createSelector(
  sellflowRoot1,
  compose(equals(true), prop('vehicleInfoLocked'))
);

export const isVehicleInfoLoading = createSelector(
  sellflowRoot1,
  propEq('isVehicleInfoLoading', true)
);

const pickMinimalTechSpec = pickAll([
  'fabricAndMake',
  'year',
  'gearbox',
  'color'
]);

const pickBrandModel = pipe(
  pathOr({}, ['technicalSpecification', 'vehicle']),
  pickAll(['brand', 'modelName'])
);

const pickAndJoinFuelCodes = pipe(
  propOr([], 'fuelCodes'),
  join('/'),
  assoc('fuel', __, {})
);

// vehicleInfoSummary merges a summary object from different paths in leadObjectInformation
// when something is not present it defaults a key to undefined
export const vehicleInfoSummary = createSelector(
  leadObjectInformation,
  (lead): VehicleInfoSummaryProps =>
    converge(unapply(mergeAll), [
      pickMinimalTechSpec,
      pickBrandModel,
      pickAndJoinFuelCodes
    ])(lead ?? {})
);

const pickBusinessProposalRegAndOdometer = pickAll([
  'registrationPlate',
  'odometerReading'
]);

export const businessProposalSummary = createSelector(businessProposal, lead =>
  converge(unapply(mergeAll), [
    pipe(propOr({}, 'baseObject'), pickAndJoinFuelCodes),
    pipe(propOr({}, 'baseObject'), pickMinimalTechSpec),
    pipe(propOr({}, 'auctionPriceInterval')),
    pickBusinessProposalRegAndOdometer
  ])(lead ?? {})
);

export const leadObjectOdometerReading = createSelector(
  leadObjectInformation,
  propOr('', 'odometerReading')
);

//TODO: implement tests for the rest of the selectors

// odometerReading uses the first truthy value in the array or else defaults to empty string
export const odometerReading = createSelector(
  sellflowRoot1,
  leadObjectOdometerReading,
  (state, odo): number =>
    // User entered data has precedence over feteched or prefilled
    ifElse(
      pipe(prop('odometerReading'), isEmpty),
      pipe(
        props(['odometerReading', 'prefilledOdometerReadingInKilometers']),
        append(String(odo)),
        reduce(or as SpreadArrayType<unknown, string>, ''),
        defaultTo('')
      ),
      prop('odometerReading')
    )(state)
);

export const leadObjectInformationError = createSelector(
  sellflowRoot1,
  sellflowRoot1 => sellflowRoot1?.leadObjectInformationError ?? ''
);

export const salesAgreementError = createSelector(
  sellflowRoot1,
  sellflowRoot1 => sellflowRoot1?.salesAgreementError ?? ''
);

export const odometerUnknown = createSelector(
  sellflowRoot1,
  sellflowRoot1 => sellflowRoot1?.odometerUnknown ?? false
);

export const isOdometerPlaceholder = createSelector(
  sellflowRoot1,
  sellflowRoot1 =>
    sellflowRoot1.leadObjectInformation === null &&
    !sellflowRoot1.odometerLocked
);

export const isOdometerEditable = createSelector(
  sellflowRoot1,
  sellflowRoot1 =>
    sellflowRoot1.leadObjectInformation !== null &&
    !sellflowRoot1.odometerLocked
);

export const isOdometerSummary = createSelector(
  sellflowRoot1,
  sellflowRoot1 => sellflowRoot1.odometerLocked
);

export const isOdometerLoading = createSelector(
  sellflowRoot1,
  sellflowRoot1 => sellflowRoot1.isOdometerLoading
);

export const trimAddonsOptions = createSelector(sellflowRoot1, state =>
  pathOr(
    [],
    ['leadObjectInformation', 'technicalSpecification', 'addons'],
    state
  )
);

export const trimOptionsLocked = createSelector(
  sellflowRoot1,
  propOr(false, 'trimOptionsLocked')
);

export const isTrimOptionsUnknown = createSelector(
  sellflowRoot1,
  sellflowRoot1 => sellflowRoot1.trimOptionsUnknown
);

export const trimOptionsPackageTextSummary = createSelector(
  vehiclePackages,
  pipe(
    filter<{ value?: boolean }>(propEq('value', true)),
    map(prop('name')),
    join(', '),
    when(isEmpty, always('-'))
  )
);

export const trimOptionsAddonsTextSummary = createSelector(
  vehicleAddonsWithSelected,
  vehicleValueAddonsWithSelected,
  (add, valAdd) =>
    pipe(
      filter(propEq('value', true)),
      map(prop('name')),
      join(', '),
      when(isEmpty, always('-'))
    )(concat(add, valAdd))
);

export const businessProposalTrimOptionsPackageTextSummary = createSelector(
  businessProposalSelectedPackage,
  pipe(propOr('', 'name'), when(isEmpty, always('-')))
);

export const businessProposalTrimOptionsAddonsTextSummary = createSelector(
  businessProposalSelectedAddons,
  addons =>
    pipe(
      filter(propEq('value', true)),
      map(prop('name')),
      join(', '),
      when(isEmpty, always('-'))
    )(addons)
);

export const isPackageUnknown = createSelector(
  sellflowRoot1,
  sellflowRoot1 => sellflowRoot1.packageUnknown
);

export const isValuationClosed = createSelector(
  sellflowRoot1,
  propOr(false, 'valuationClosed')
);

export const isSalesMethodClosed = createSelector(
  sellflowRoot1,
  propOr(false, 'salesMethodClosed')
);

export const salesMethod = createSelector(
  sellflowRoot1,
  ({ salesMethod }) => salesMethod || ''
);

export const isTrimOptionsEdited = createSelector(
  sellflowRoot1,
  propOr(false, 'isTrimOptionsEdited')
);

export const isNoticeOfInterestSelector = createSelector(
  leadObjectInformation,
  lead => lead?.salesFlow === SELL_FLOWS.NOTICE_OF_INTEREST
);

export const isCorporateCar = createSelector(
  leadObjectInformation,
  lead => lead?.salesFlow === SELL_FLOWS.CORPORATE_CARS
);

export const useErrorFallbackSolutionSelector = createSelector(
  sellflowRoot1,
  sellflowRoot1 => sellflowRoot1?.useErrorFallbackSolution
);

export const shouldJumpDirectlyToContactForm = createSelector(
  isNoticeOfInterestSelector,
  useErrorFallbackSolutionSelector,
  (isNoticeOfInterest, useErrorFallbackSolution) =>
    isNoticeOfInterest || useErrorFallbackSolution
);

export const isContactFormPlaceholder = createSelector(
  sellflowRoot1,
  shouldJumpDirectlyToContactForm,
  (state, shouldJump) =>
    anyPass<SellFlow1>([
      propEq('vehicleInfoLocked', false),
      propEq('odometerLocked', false),
      propEq('trimOptionsLocked', false),
      propEq('salesMethodClosed', false),
      propEq('valuationClosed', false)
    ])(state) && !shouldJump
);

export const isContactFormEditable = createSelector(
  sellflowRoot1,
  shouldJumpDirectlyToContactForm,
  (state, shouldJump) =>
    allPass([
      propEq('vehicleInfoLocked', true),
      propEq('odometerLocked', true),
      propEq('trimOptionsLocked', true),
      propEq('salesMethodClosed', true),
      propEq('valuationClosed', true)
    ])(state) || shouldJump
);

export const sellflowChecklist = createSelector(
  registrationPlate,
  odometerReading,
  packageSelected,
  addonsSelected,
  valuationStandardAddonsSelected,
  (reg, odo: number, pkt, add, valAdd) => [
    { title: 'Reg nr', done: reg.length > 0 },
    {
      title: 'Odometer reading',
      done: !(isNil(odo) || isEmpty(odo) || odo === 0)
    },
    { title: 'Equipment package', done: pkt.length > 0 },
    { title: 'Services', done: add.length + valAdd.length > 0 }
  ]
);

export const hasCompletedAllSteps = createSelector(
  sellflowChecklist,
  checklist => checklist.filter(step => step.done).length === checklist.length
);

export const isBusinessProposalLoading = createSelector(
  sellflowRoot1,
  sellFlow1 => sellFlow1?.businessProposalLoading ?? false
);

export const businessProposalError = createSelector(
  sellflowRoot1,
  sellFlow1 => sellFlow1?.businessProposalError ?? ''
);

export const hasBusinessProposal = createSelector(
  sellflowRoot1,
  compose(not, isEmpty, propOr({}, 'businessProposal'))
);

export const isTrimOptionsLoading = createSelector(
  sellflowRoot1,
  propEq('isTrimOptionsLoading', true)
);

export const isTrimOptionsEdit = createSelector(
  sellflowRoot1,
  allPass([
    propEq('vehicleInfoLocked', true),
    propEq('odometerLocked', true),
    propEq('trimOptionsLocked', false),
    compose(
      not,
      isEmpty,
      pathOr({}, ['leadObjectInformation', 'technicalSpecification'])
    )
  ])
);

export const isTrimOptionsSummary = createSelector(
  sellflowRoot1,
  anyPass([
    allPass([
      propEq('vehicleInfoLocked', true),
      propEq('odometerLocked', true),
      propEq('trimOptionsLocked', true),
      compose(
        not,
        isEmpty,
        pathOr({}, ['leadObjectInformation', 'technicalSpecification'])
      )
    ]),
    allPass([
      anyPass<SellFlow1>([
        propEq('vehicleInfoLocked', false),
        propEq('odometerLocked', false)
      ]),
      propEq('isTrimOptionsEdited', true)
    ])
  ])
);

export const isValuationPlaceholder = createSelector(
  sellflowRoot1,
  anyPass<SellFlow1>([
    propEq('vehicleInfoLocked', false),
    propEq('odometerLocked', false),
    propEq('trimOptionsLocked', false),
    propEq('isVehicleInfoLoading', true),
    propEq('isOdometerLoading', true),
    propEq('isTrimOptionsLoading', true)
  ])
);

export const isValuationSummary = createSelector(isValuationPlaceholder, not);

export const isSalesMethodPlaceholder = createSelector(
  sellflowRoot1,
  anyPass<SellFlow1>([
    propEq('vehicleInfoLocked', false),
    propEq('odometerLocked', false),
    propEq('trimOptionsLocked', false),
    propEq('isVehicleInfoLoading', true),
    propEq('isOdometerLoading', true),
    propEq('isTrimOptionsLoading', true)
  ])
);

export const isSalesMethodEdit = createSelector(isSalesMethodPlaceholder, not);

export const auctionPriceInterval = createSelector(
  leadObjectInformation,
  businessProposal,
  (leadO: LeadObjectInformation, proposal: BusinessProposal) =>
    leadO?.auctionPriceInterval
      ? leadO.auctionPriceInterval
      : proposal?.auctionPriceInterval
);

export const auctionPriceIntervalValue = createSelector(
  auctionPriceInterval,
  (interval): number => {
    if (not(propOr(null, 'value', interval))) {
      const max: number = propOr(0, 'max', interval);
      const min: number = propOr(0, 'min', interval);
      return (max + min) / 2;
    } else {
      return propOr(0, 'value', interval);
    }
  }
);

export const auctionPriceIntervalMin = createSelector(
  auctionPriceInterval,
  auctionPriceIntervalValue,
  (interval: Interval, val: number) =>
    val - propOr<number, Interval, number>(0, 'min', interval)
);

export const auctionPriceIntervalMax = createSelector(
  auctionPriceInterval,
  auctionPriceIntervalValue,
  (interval: Interval, val: number) =>
    propOr<number, Interval, number>(0, 'max', interval) - val
);

export const tradeInPriceInterval = createSelector(
  leadObjectInformation,
  businessProposal,
  (leadO: LeadObjectInformation, proposal: BusinessProposal) =>
    leadO?.tradeInPriceInterval
      ? leadO.tradeInPriceInterval
      : proposal?.tradeInPriceInterval
);

export const tradeInPriceIntervalValue = createSelector(
  tradeInPriceInterval,
  (interval): number => {
    return propOr(0, 'value', interval);
  }
);

export const tradeInPriceIntervalMin = createSelector(
  tradeInPriceInterval,
  tradeInPriceIntervalValue,
  (interval: Interval, val: number) =>
    val - propOr<number, Interval, number>(0, 'min', interval)
);

export const tradeInPriceIntervalMax = createSelector(
  tradeInPriceInterval,
  tradeInPriceIntervalValue,
  (interval: Interval, val: number) =>
    propOr<number, Interval, number>(0, 'max', interval) - val
);

export const salesFlowCurrentStep = createSelector(
  isVehicleInfoEditable,
  shouldJumpDirectlyToContactForm,
  isOdometerEditable,
  isTrimOptionsEdit,
  isValuationSummary,
  isSalesMethodEdit,
  isContactFormEditable,
  (
    regPlate,
    jumpDirectly,
    odometer,
    trimOptions,
    valuation,
    salesMethod,
    contactForm
  ): string => {
    if (regPlate) {
      return 'registreringsNummer';
    }
    if (jumpDirectly || contactForm) {
      return 'kontaktformulär';
    }
    if (odometer) {
      return 'miltal';
    }
    if (trimOptions) {
      return 'utrustning';
    }
    if (valuation) {
      return 'värdering';
    }
    if (salesMethod) {
      return 'försäljningsmetod';
    }
    return '';
  }
);

export const salesFlowType = createSelector(
  salesFlow,
  salesFlowBusinessProposal,
  (salesFlowLead, salesFlowBusiness) => {
    // use salesFlow from businessProposal object first - it's more accurate
    return getPrivateOrCompanySalesFlow(salesFlowBusiness || salesFlowLead);
  }
);

export const salesFlowQueryParameters = createSelector(
  salesFlowType,
  salesFlowCurrentStep,
  (salesFlowType, salesFlowCurrentStep) => {
    const query: Record<string, string> = {};

    // Create query parameter for the sellflow private / company
    if (salesFlowType) {
      query.sf = salesFlowType === 'PRIVATE' ? 'privat' : 'företag';
    }

    // Create query parameter for the sellflow private / company
    if (salesFlowCurrentStep) {
      query.cs = salesFlowCurrentStep;
    }

    return query;
  }
);
