import React from 'react';
import {
  AwinConversionElementType,
  AwinConversionType
} from '~/App/shared/types/AwinConversionType';
import AwinConversionElement, {
  AwinConversionRef
} from '../../../AwinTags/AwinConversionElement';
import { fromAffiliateClick } from '../../../AwinTags/helper';

interface Props {
  orderRef: string;
  conversionTagRef: React.RefObject<AwinConversionRef>;
  conversionPixelRef: React.RefObject<AwinConversionRef>;
}

const AwinAffiliateElement = ({
  orderRef,
  conversionTagRef,
  conversionPixelRef
}: Props) => {
  return (
    <>
      {fromAffiliateClick && (
        <>
          <AwinConversionElement
            id={'member-conversion-tag'}
            orderRef={orderRef}
            commissionGroup={AwinConversionType.MEMBER}
            conversionElement={AwinConversionElementType.TAG}
            ref={conversionTagRef}
          />
          <AwinConversionElement
            id={'member-fall-back-pixel'}
            orderRef={orderRef}
            commissionGroup={AwinConversionType.MEMBER}
            conversionElement={AwinConversionElementType.PIXEL}
            ref={conversionPixelRef}
          />
        </>
      )}
    </>
  );
};

export default AwinAffiliateElement;
