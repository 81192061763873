import axios from '~/helpers/orchestration/axiosWithAuthHandling';
import { orchestration } from '~/config/public';
import { get as getCookie, getGaClientId } from '~/helpers/client/cookie';
import { Member } from '~/App/shared/interfaces/Member';
import { Auth, Session } from '~/App/shared/interfaces/store/Session';
import { DeepPartial } from '@test/mocks';
import { Nullable } from '~/App/shared/types/Nullable';
import { getCookieConsent } from '../cookieConsent';

type Person = Partial<Member['person']>;
export interface MemberInput extends DeepPartial<Member> {
  person: Person & {
    password: string;
    acceptsMinimumAgeRequirement: boolean;
    isCarDealer?: boolean;
  };
  verifyPhone?: boolean;
  termsAccepted: boolean;
  affiliateClickId: Nullable<string>;
}

export type CreateMemberArgs = {
  member: MemberInput;
  gRecaptchaResponse: string;
};
export const createMember = async ({
  member,
  gRecaptchaResponse
}: CreateMemberArgs) => {
  const { data } = await axios.post<Session>(
    `${orchestration.url}/common/members`,
    {
      member: {
        ...member,
        didomiToken: getCookie('didomi_token'),
        cookieConsent: getCookieConsent()
      },
      gRecaptchaResponse: gRecaptchaResponse || {},
      GaClientId: getGaClientId()
    },
    {
      responseType: 'json'
    }
  );
  return data;
};

type UpdateMemberArgs = {
  data: DeepPartial<Member>;
  idToken: string;
};
export const updateMemberSimple = ({ data, idToken }: UpdateMemberArgs) =>
  axios.put<Session>(`${orchestration.url}/common/members/me/simple`, data, {
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    responseType: 'json'
  });

export type GetMemberResponse = {
  auth: Auth;
  member: Member;
  socialIdpSession: boolean;
};
export const getMember = (idToken: string) =>
  axios
    .get<GetMemberResponse>(`${orchestration.url}/common/members/me`, {
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      withCredentials: true,
      responseType: 'json'
    })
    .then(({ data }) => data);
